import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/Layout/index.jsx";
import Body from '../../components/Body';
import Headline from '../../components/Body/Headline';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Body withoutHero mdxType="Body">
  <Headline level="1" standalone offsetLeft mdxType="Headline">Impressum/ Legal disclosure</Headline>
  
  <p style={{
        color: '#0095FF',
        marginTop: '-1rem'
      }}><AnchorLink to="/imprint#english" mdxType="AnchorLink">(English)</AnchorLink></p>
      <p>{`  `}<strong parentName="p">{`Rechtssitz`}</strong><br />{`
Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV`}<br />{`
Angaben gemäß § 5 TMG`}</p>
      <p>{`  Handbreit - nautical safety solutions gGmbH`}<br />{`
Moosdorfstr. 7-9`}<br />{`
12435 Berlin`}<br />{`
Deutschland`}</p>
      <p>{`  `}<strong parentName="p">{`Geschäftsführung`}</strong><br />{`
Anna Leona Blankenstein`}</p>
      <p>{`  Kontakt zum Projekt: `}<inlineCode parentName="p">{`contact@mvlouisemichel.org`}</inlineCode><br />{`
Kontakt zum Media- / Presseteam: `}<inlineCode parentName="p">{`press@mvlouisemichel.org`}</inlineCode></p>
      <p>{`  `}<strong parentName="p">{`Warum keine Telefonnummer?`}</strong><br />{`
Die im Mittelmeer aktiven NGOs sehen sich immer wieder Anfeindungen ausgesetzt. Die Erfahrung hat leider gezeigt, dass mit dem bereitstellen einer Telefonnummer viel Unfug betrieben wird. Von Beleidigungen bis hin zu Drohungen. Wir bitten sie um Verständnis, dass wir deshalb keine Telefonnummer hier bereitstellen wollen. In dringenden Fällen schreiben sie und gerne eine Mail mit ihrer Nummer und wir rufen sie zeitnah zurück.`}</p>
      <p>{`  `}<strong parentName="p">{`Registereintrag`}</strong><br />{`
Eintragung im Handelsregister`}<br />{`
Registergericht: Amtsgericht Berlin Charlottenburg`}<br />{`
Registernummer: HRB 217048 B`}</p>
      <p>{`  `}<strong parentName="p">{`Umsatzsteuer-ID`}</strong><br />{`
Umsatzsteuer-Identifikationsnummer gemäß §27 a Umsatzsteuergesetz: DE330050799`}</p>
      <p>{`  `}<strong parentName="p">{`Gemeinnützigkeit / Freistellungsbescheid`}</strong><br />{`
Handbreit - nautical safety solutions gGmbH ist wegen Förderung der Rettung aus Lebensgefahr, des bürgerschaftlichen Engagements zugunsten gemeinnütziger Zwecke und der Volks- und Berufsbildung einschließlich der Studentenhilfe nach der Anlage zum Körperschaftsteuerbescheid des Finanzamtes für Körperschaften I, Berlin, Steuer-Nr. 27/613/05447 F131, vom 09.05.2020 für den letzten Veranlagungszeitraum 2020 nach § 5 Abs. 1 Nr. 9 des Körperschaftsteuergesetzes von der Körperschaftsteuer und nach § 3 Nr. 6 des Gewerbesteuergesetzes von der Gewerbesteuer befreit.`}</p>
      <p>{`  `}<strong parentName="p">{`Haftung für Inhalte`}</strong><br />{`
Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.`}</p>
      <p>{`  `}<strong parentName="p">{`Haftung für Links`}</strong><br />{`
Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.`}</p>
      <p>{`  `}<strong parentName="p">{`Urheberrecht`}</strong><br />{`
Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.`}</p>
  <br /><br />
  
  <div id="english" style={{
        position: 'relative',
        top: '-95px',
        visibility: 'hidden'
      }}></div>
      <p>{`  `}<strong parentName="p">{`Legal Domicile`}</strong><br />{`
Responsible for the content according to Section 55 Abs. 2 RStV`}<br />{`
Information according to Section 5 of the German Telemedia Act (TMG)`}</p>
      <p>{`  `}<strong parentName="p">{`Contact`}</strong><br />{`
Handbreit - nautical safety solutions gGmbH`}<br />{`
Moosdorfstr. 7-912435 Berlin`}<br />{`
Germany`}</p>
      <p>{`  `}<strong parentName="p">{`Directors`}</strong><br />{`
Anna Leona Blankenstein`}<br />{`
Enquiries : `}<inlineCode parentName="p">{`contact@mvlouisemichel.org`}</inlineCode><br />{`
Press Enquiries : `}<inlineCode parentName="p">{`press@mvlouisemichel.org`}</inlineCode></p>
      <p>{`  `}<strong parentName="p">{`Why no contact phone number?`}</strong><br />{`
The NGOs active in the Mediterranean Sea face hostility again and again. Unfortunately, experience has shown that a lot of mischief is done with the provision of a telephone number, from insults to threats. In urgent cases, please send us an email with your number and we will call you back as soon as possible.`}</p>
      <p>{`  `}<strong parentName="p">{`Register Entry`}</strong><br />{`
Entry in the register of associations.`}<br />{`
Registration court: District Court Berlin Charlottenburg`}<br />{`
Registration number: HRB 217048 B`}</p>
      <p>{`  `}<strong parentName="p">{`Sale Tax`}</strong><br />{`
Sales tax identification number according to §27, a German sales tax law: DE330050799`}</p>
      <p>{`  `}<strong parentName="p">{`Non-Profit Status / Notice of Exemption`}</strong><br />{`
Handbreit - nautical safety solutions gGmbH is exempt from corporate income tax for the last assessment period 2020 due to the promotion of rescue from life-threatening danger, civic engagement in favour of charitable purposes and popular and vocational education including student aid according to the annex to the corporate income tax assessment notice of the Tax Office for Corporations I, Berlin, tax no. 27/613/05447 F131, dated May 9, 2020, for the last assessment period 2020, the Company is exempt from corporate income tax pursuant to Section 5 (1) No. 9 of the German Corporate Income Tax Act and from trade tax pursuant to Section 3 No. 6 of the German Trade Tax Act.`}</p>
      <p>{`  `}<strong parentName="p">{`Liability for contents`}</strong><br />{`
As a service provider, we are responsible for our own content on these pages in accordance with general legislation pursuant to Section 7 (1) of the German Telemedia Act (TMG). According to Section 8 to 10 TMG, however, we are not obligated as a service provider to monitor transmitted or stored third-party information or to investigate circumstances that indicate illegal activity. Obligations to remove or block the use of information under the general laws remain unaffected. However, liability in this regard is only possible from the point in time at which a concrete infringement of the law becomes known. If we become aware of such infringements, we will remove this content immediately.`}</p>
      <p>{`  `}<strong parentName="p">{`Liability for external links`}</strong><br />{`
Our offer contains links to external websites of third parties, on whose contents we have no influence. Therefore, we cannot assume any liability for these external contents. The respective provider or operator of the pages is always responsible for the content of the linked pages. The linked pages were checked for possible legal violations at the time of linking. Illegal contents were not recognizable at the time of linking. However, a permanent control of the contents of the linked pages is not reasonable without concrete evidence of a violation of the law. If we become aware of any infringements, we will remove such links immediately.`}</p>
      <p>{`  `}<strong parentName="p">{`Copyright`}</strong><br />{`
The contents and works created by the site operators on these pages are subject to German copyright law. The contents of this web site may not be used without prior permission of the owner. Duplication, processing, distribution, or any form of commercialisation of such material beyond the scope of the copyright law shall require the prior written consent of its respective author or creator. Insofar as the content on this site was not created by the operator, the copyrights of third parties are respected. In particular, third-party content is identified as such. Should you nevertheless become aware of a copyright infringement, please inform us accordingly. If we become aware of any infringements, we will remove such content immediately.`}</p>
    </Body>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      